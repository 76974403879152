export const ALI_Recorder = {
    mediaRecorder: null,
    audioChunks: [],
    stopTimer: null,
    countdownInteveral: null,

    supportedTypes: [
        "audio/webm;codecs=opus",
        "audio/ogg;codecs=opus",
        "audio/wav",
        "audio/mpeg"
    ],


    // Initialize the Recorder
    init: function () {
        this.supportedTypes.forEach((type) => {
            console.log(type, MediaRecorder.isTypeSupported(type) ? "Supported" : "Not Supported");
        });
        //this.startButton.addEventListener("click", this.startRecording.bind(this));
        //this.stopButton.addEventListener("click", this.stopRecording.bind(this));
    },

    // Start recording audio
    startRecording: async function (duration = null) {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.mediaRecorder = new MediaRecorder(stream);

            console.log("Selected MIME type:", this.mediaRecorder.mimeType);

            // Reset audio chunks
            this.audioChunks = [];
            this.mediaRecorder.addEventListener("dataavailable", (event) => {
                this.audioChunks.push(event.data);
            });

            this.mediaRecorder.start();
            console.log("Recording started...");

            // Fire "recordingStarted" event when the recording officially starts
            this.mediaRecorder.addEventListener("start", () => {
                console.log("Recording has officially started!");
                const event = new CustomEvent("recordingStarted");
                document.dispatchEvent(event);
            });

            if(duration && duration > 0) {
                let timeRemaining = duration;

                this.countdownInteveral = setInterval(() => {
                    timeRemaining--;
                    document.dispatchEvent(new CustomEvent("recordingTick", {detail: {timeRemaining}}));

                    if (timeRemaining <= 0) {
                        clearInterval(this.countdownInteveral);
                    }
                }, 1000);
                this.stopTimer = setTimeout(() => {
                    this.stopRecording();
                }, duration * 1000);
            }
        } catch (error) {
            console.error("Error accessing microphone:", error);
        }
    },

    // Stop recording audio
    stopRecording: function () {
        if (this.mediaRecorder) {
            if (this.stopTimer) {
                clearTimeout(this.stopTimer);
                this.stopTimer = null;
            }

            if(this.countdownInteveral) {
                clearInterval(this.countdownInteveral);
                this.countdownInteveral = null;
            }

            this.mediaRecorder.stop();
            console.log("Recording stopped...");

            this.mediaRecorder.addEventListener("stop", () => {
                const audioURL = this.processRecording();

                //Dispatch custom event when recording stops
                const event = new CustomEvent("recordingStopped", {detail: {audioURL}});
                document.dispatchEvent(event);
            });
        }
    },

    get_blob: function () {
        return new Blob(this.audioChunks, { type: "audio/webm" });
    },

    // Process the recorded audio
    processRecording: function () {
        const audioBlob = new Blob(this.audioChunks, { type: "audio/webm" });
        const audioURL = URL.createObjectURL(audioBlob);
        console.log("Audio processing complete.");
        return audioURL;
    }
};

// Initialize the Recorder object
//ALI_Recorder.init();
