import React, {useEffect, useState} from 'react';
import DStyles from './styles/DictionaryStyles.module.css';
import {talk} from "./ALI_Ajax"
import Loading from "./images/loading_gif_large.gif"
import GenStyles from "./styles/GenStyles.module.css";
import LoginModule from "./login_module";
import {useUser} from "./UserContext";
import {useNavigate} from "react-router-dom";


const DictionaryBuilder = () => {
    const [words, setWords] = useState([]);
    const [inputText, setInputText] = useState('');
    const [audioFilesExistence, setAudioFilesExistence] = useState([]);
    const [imageFilesExistence, setImageFilesExistence] = useState([]);
    const [imagesBeingGenerated, setImagesBeingGenerated] = useState([]);
    const [audioBeingGenerated, setAudioBeingGenerated] = useState([]);
    const {userId, finishedLoggingIn, is_user_in_role} = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (finishedLoggingIn && !is_user_in_role('ADMIN')) {
            navigate("/"); // Redirect to home page
        }
    }, [finishedLoggingIn, navigate]);


    const check_for_files_on_server = (wordList) => {
        let postData = {words: wordList}
        talk(
            "./ali_api/ALIFileCheck_Handler.php",
            "CHECK_DICTIONARY_IMAGE_FILES_EXIST",
            postData,
            check_for_files_on_server_handler
        );
        talk(
            "./ali_api/ALIFileCheck_Handler.php",
            "CHECK_DICTIONARY_AUDIO_FILES_EXIST",
            postData,
            check_for_files_on_server_handler
        );
    }
    const check_for_files_on_server_handler = (operation, sent_data, return_data) => {
        if(operation === "CHECK_DICTIONARY_IMAGE_FILES_EXIST") {
            setImageFilesExistence(return_data);
        } else {
            setAudioFilesExistence(return_data);
        }

        console.log(operation);
        console.log(return_data);
    }

    const handleGenerate = () => {
        let wordList = inputText.split('\n').filter(word => word.trim() !== '');
        setWords(wordList);
        check_for_files_on_server(wordList);
    };

    const handlePlayAudio = (word) => {
        console.log(`Play audio for: ${word}`);
    };

    const generate_audio = (word) => {
        console.log(`Generate audio for: ${word}`);

        let localABG = JSON.parse(JSON.stringify(audioBeingGenerated));
        localABG.push(word);
        setAudioBeingGenerated(localABG);

        talk("./ali_api/OpenAI_Handler.php",
            "GENERATE_DICTIONARY_AUDIO",
            {prompt:word, filename:word, save_folder:"dictionary"},
            generate_audio_handler)
    };
    const generate_audio_handler = (operation, sent_data, return_data) => {
        setAudioBeingGenerated(audioBeingGenerated.filter(item => item !== sent_data['prompt']));

        let localAFE = JSON.parse(JSON.stringify(audioFilesExistence));
        localAFE[sent_data['prompt']] = true;
        setAudioFilesExistence(localAFE);
        console.log(return_data);
    }

    const generate_image = (word) => {
        let localIBG = JSON.parse(JSON.stringify(imagesBeingGenerated));
        localIBG.push(word);
        setImagesBeingGenerated(localIBG);
        console.log(`Create image for: ${word}`);

        talk("./ali_api/OpenAI_Handler.php",
            "GENERATE_DALLE_IMAGE",
            {prompt:word, filename:word, save_folder:"dictionary"},
            generate_image_handler)
    };
    const generate_image_handler = (operation, sent_data, return_data) => {
        setImagesBeingGenerated(imagesBeingGenerated.filter(item => item !== sent_data['prompt']));

        let localIFE = JSON.parse(JSON.stringify(imageFilesExistence));
        localIFE[sent_data['prompt']] = true;
        setImageFilesExistence(localIFE);
        console.log(return_data);
    }

    return (
        <>
            {
                finishedLoggingIn  && is_user_in_role('ADMIN') ?
                    <>
                        {/* Header */}
                        <div className={GenStyles.container} style={{marginTop: "80px"}} id="showcase">
                            <h1 className={GenStyles.jumbo}><b>ALI</b></h1>
                            <h1 className={`${GenStyles.xxxlarge} ${GenStyles.text_umkc_blue}`}><b>Text to Speech</b></h1>
                            <hr style={{width: "50px", border: "5px solid #ffd52f"}} className={GenStyles.round}/>
                        </div>

                        {userId ?
                            <div className={DStyles.dictionaryBuilder}>
                                <textarea
                                  className={DStyles.textInput}
                                  value={inputText}
                                  onChange={(e) => setInputText(e.target.value)}
                                  placeholder="Enter vocabulary words, one per line..."
                                />
                                <button className={DStyles.generateButton} onClick={handleGenerate}>Check</button>
                                <div className={DStyles.wordList}>
                                    {words.map((word, index) => {
                                        const imagePath = `./images/dictionary/${word}.jpg`;
                                        const audioPath = `./audio/dictionary/${word}.mp3`;
                                        return (
                                            <div key={index} className={DStyles.wordItem}>
                                                <div className={DStyles.wordText}>{word}</div>
                                                <div className={DStyles.mediaContainer}>
                                                    {
                                                        imagesBeingGenerated.includes(word) ? (
                                                            <img
                                                                src={Loading}
                                                                alt={"loading"}
                                                                className={DStyles.wordImage}
                                                            />
                                                            ) : (

                                                        imageFilesExistence[word] ? (
                                                        <img
                                                            src={imagePath}
                                                            alt={word}
                                                            className={DStyles.wordImage}
                                                        />
                                                    ) : (
                                                        <div className={DStyles.noImagePlaceholder}>NO IMAGE FOUND</div>
                                                    ))}
                                                    <button className={imageFilesExistence[word] ? DStyles.recreateButton : DStyles.createButton} onClick={() => generate_image(word)}>
                                                        {imageFilesExistence[word] ? "Re-Create Image" : "Create Image"}
                                                    </button>
                                                </div>
                                                <div className={DStyles.audioContainer}>
                                                    {
                                                        audioBeingGenerated.includes(word) ? (
                                                            <img
                                                                src={Loading}
                                                                alt={"loading"}
                                                                className={DStyles.wordImage}
                                                            />
                                                        ) : (

                                                            audioFilesExistence[word] ? (
                                                                <audio
                                                                    controls
                                                                    src={audioPath}
                                                                    onError={(e) => e.target.style.display = 'none'}
                                                                />
                                                            ) : (
                                                                <div className={DStyles.noImagePlaceholder}>NO AUDIO FOUND</div>
                                                            ))}
                                                    <button className={audioFilesExistence[word] ? DStyles.regenerateButton : DStyles.generateButton} onClick={() => generate_audio(word)}>
                                                        {audioFilesExistence[word] ? "Regenerate Audio" : "Generate Audio"}
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        : <LoginModule />}
                </> :
                <div>Loading</div>
            }
        </>
    );
};

export default DictionaryBuilder;