import React, {useEffect, useState, useRef} from "react";
import GenStyles from "./styles/GenStyles.module.css";
import CEStyles from "./styles/CommunityEnglishStyles.module.css";
import {talk} from "./ALI_Ajax.js";
import {useUser} from "./UserContext";
import Foundations from "./images/foundations.jpg";
import Pathways from "./images/pathways.jpg";
import Horizons from "./images/horizons.jpg";

import LoginModule from "./login_module";
import {FaChevronDown} from "react-icons/fa";
import Competencies from "./files/CE_Core_Competencies.pdf";
import Greetings from "./files/Greetings_and_Meetings.pdf";
import Slideshow from "./slideshow";
import Dictionary from "./Dictionary";
import VocabularyQuiz from "./VocabularyQuiz";

const CEFoundations = (props) => {

    const tableData = [
        // Header row
        ["Word", "Part of Speech", "Category"],
        // Data rows
        ["cat", "noun", "animals"],
        ["dog", "noun", "animals"],
        ["bird", "noun", "animals"],
        ["fish", "noun", "animals"],
        ["cow", "noun", "animals"],
        ["horse", "noun", "animals"],
        ["pig", "noun", "animals"],
        ["sheep", "noun", "animals"],
        ["chicken", "noun", "animals"],
        ["duck", "noun", "animals"],
        ["head", "noun", "body parts"],
        ["face", "noun", "body parts"],
        ["eye", "noun", "body parts"],
        ["ear", "noun", "body parts"],
        ["nose", "noun", "body parts"],
        ["mouth", "noun", "body parts"],
        ["hand", "noun", "body parts"],
        ["arm", "noun", "body parts"],
        ["leg", "noun", "body parts"],
        ["foot", "noun", "body parts"],
        ["apple", "noun", "food"],
        ["banana", "noun", "food"],
        ["bread", "noun", "food"],
        ["water", "noun", "food"],
        ["milk", "noun", "food"],
        ["rice", "noun", "food"],
        ["egg", "noun", "food"],
        ["soup", "noun", "food"],
        ["meat", "noun", "food"],
        ["fruit", "noun", "food"],
        ["mother", "noun", "family"],
        ["father", "noun", "family"],
        ["sister", "noun", "family"],
        ["brother", "noun", "family"],
        ["baby", "noun", "family"],
        ["grandma", "noun", "family"],
        ["grandpa", "noun", "family"],
        ["shirt", "noun", "clothing"],
        ["pants", "noun", "clothing"],
        ["dress", "noun", "clothing"],
        ["shoes", "noun", "clothing"],
        ["hat", "noun", "clothing"],
        ["coat", "noun", "clothing"],
        ["socks", "noun", "clothing"],
        ["skirt", "noun", "clothing"],
        ["jacket", "noun", "clothing"],
        ["tie", "noun", "clothing"],
        ["school", "noun", "places"],
        ["home", "noun", "places"],
        ["store", "noun", "places"],
        ["park", "noun", "places"],
        ["hospital", "noun", "places"],
        ["restaurant", "noun", "places"],
        ["library", "noun", "places"],
        ["bank", "noun", "places"],
        ["market", "noun", "places"],
        ["mall", "noun", "places"],
        ["chair", "noun", "furniture"],
        ["table", "noun", "furniture"],
        ["bed", "noun", "furniture"],
        ["sofa", "noun", "furniture"],
        ["door", "noun", "furniture"],
        ["window", "noun", "furniture"],
        ["desk", "noun", "furniture"],
        ["car", "noun", "vehicles"],
        ["bus", "noun", "vehicles"],
        ["bike", "noun", "vehicles"],
        ["be", "verb", ""],
        ["have", "verb", ""],
        ["do", "verb", ""],
        ["go", "verb", ""],
        ["come", "verb", ""],
        ["see", "verb", ""],
        ["eat", "verb", ""],
        ["sleep", "verb", ""],
        ["read", "verb", ""],
        ["write", "verb", ""],
        ["speak", "verb", ""],
        ["listen", "verb", ""],
        ["run", "verb", ""],
        ["walk", "verb", ""],
        ["play", "verb", ""],
        ["work", "verb", ""],
        ["like", "verb", ""],
        ["want", "verb", ""],
        ["need", "verb", ""],
        ["know", "verb", ""],
        ["good", "adjective", ""],
        ["bad", "adjective", ""],
        ["big", "adjective", ""],
        ["small", "adjective", ""],
        ["happy", "adjective", ""],
        ["sad", "adjective", ""],
        ["hot", "adjective", ""],
        ["cold", "adjective", ""],
        ["new", "adjective", ""],
        ["old", "adjective", ""],
        ["fast", "adjective", ""],
        ["slow", "adjective", ""],
        ["tall", "adjective", ""],
        ["short", "adjective", ""],
        ["easy", "adjective", ""],
        ["difficult", "adjective", ""],
        ["in", "preposition", ""],
        ["on", "preposition", ""],
        ["at", "preposition", ""],
        ["by", "preposition", ""],
        ["with", "preposition", ""],
        ["from", "preposition", ""],
        ["to", "preposition", ""],
        ["for", "preposition", ""],
        ["about", "preposition", ""],
        ["I", "pronoun", ""],
        ["you", "pronoun", ""],
        ["he", "pronoun", ""],
        ["she", "pronoun", ""],
        ["it", "pronoun", ""],
        ["we", "pronoun", ""],
        ["they", "pronoun", ""],
        ["me", "pronoun", ""],
        ["him", "pronoun", ""],
        ["her", "pronoun", ""],
        ["us", "pronoun", ""],
        ["them", "pronoun", ""],
        ["and", "conjunction", ""],
        ["but", "conjunction", ""],
        ["or", "conjunction", ""],
        ["so", "conjunction", ""],
        ["because", "conjunction", ""],
        ["if", "conjunction", ""],
        ["when", "conjunction", ""],
        ["a", "determiner", ""],
        ["an", "determiner", ""],
        ["the", "determiner", ""],
        ["this", "determiner", ""],
        ["that", "determiner", ""],
        ["these", "determiner", ""],
        ["those", "determiner", ""],
        ["some", "determiner", ""],
        ["any", "determiner", ""],
        ["one", "number", ""],
        ["two", "number", ""],
        ["three", "number", ""],
        ["four", "number", ""],
        ["five", "number", ""],
        ["six", "number", ""],
        ["seven", "number", ""],
        ["eight", "number", ""],
        ["nine", "number", ""],
        ["ten", "number", ""]
    ];

    const [expandedDiv, setExpandedDiv] = useState(null);


    const set_open_div = (which_div) => {
        console.log(which_div);

        //If clicking the same div a second time, close it
        if(which_div === expandedDiv) {
            setExpandedDiv(null);
        } else {
            setExpandedDiv(which_div);
        }
    }

    
    return (
        <>
            {/* Header */}
            <div className={GenStyles.container} style={{marginTop: "80px"}} id="showcase">
                <h1 className={GenStyles.jumbo}><b>ALI Community English</b></h1>
                <h1 className={`${GenStyles.xxxlarge} ${GenStyles.text_umkc_blue}`}><b>CE Foundations</b></h1>
                <hr style={{width: "50px", border: "5px solid #ffd52f"}} className={GenStyles.round}/>
            </div>
            <div className={GenStyles.container}>
                <div className={`${CEStyles.group_item} ${CEStyles.foundations}`} >
                    <img src={Foundations} className={CEStyles.group_image}/>
                    <div className={CEStyles.group_title}>Foundations</div>
                </div>

                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('alphabet')}>
                        <h2><FaChevronDown
                            className={`chevron ${expandedDiv === 'alphabet' ? "chevron_rotate" : ""}`}/> Alphabet (A B
                            C D ...)</h2>
                    </div>
                    <div className={expandedDiv === 'alphabet' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.column_container}>

                            <iframe width="560" height="315" src="https://www.youtube.com/embed/Bz3Q1kqDRw4?si=IUFptoHNJkOg4AZ5"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    style={{"padding": "20px"}}
                                    allowFullScreen></iframe>

                            <iframe width="560" height="315" src="https://www.youtube.com/embed/ExZiADS3a5s?si=2XxVOS_P4WK8VaOc"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    style={{"padding": "20px"}}
                                    allowFullScreen></iframe>
                        </div>
                    </div>
                </div>

                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('greetings')}>
                        <h2><FaChevronDown
                            className={`chevron ${expandedDiv === 'greetings' ? "chevron_rotate" : ""}`}/> Greetings
                        </h2>
                    </div>
                    <div className={expandedDiv === 'greetings' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.column_container}>
                            <Slideshow slides={
                                [
                                    "hello_male",
                                    "hi_female",
                                    "how_are_you_male",
                                    "fine_how_are_you_female",
                                    "good_thank_you_male",
                                    "whats_your_name_female",
                                    "my_name_is_ben_male",
                                    "whats_your_name_male",
                                    "my_name_is_mary_female",
                                    "nice_to_meet_you_mary_male",
                                    "nice_to_meet_you_too_ben_female",
                                    "how_are_you_variations",
                                    "hello_variations"
                                ]}/>

                            <iframe
                                src={Greetings}
                                width="100%"
                                height="600px"
                                title="PDF Viewer"
                                style={{ border: 'none' }}
                            />
                        </div>
                    </div>
                </div>

                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('vocabulary-numbers-1-10')}>
                        <h2><FaChevronDown className={`chevron ${expandedDiv === 'vocabulary-numbers-1-10' ? "chevron_rotate" : ""}`} /> Vocabulary - Numbers 1-10</h2>
                    </div>
                    <div className={expandedDiv === 'vocabulary-numbers-1-10' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.column_container}>
                            <VocabularyQuiz words={[
                                "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten", "zero"
                            ]} onClose={() => alert("Closing")} />
                        </div>
                        <div className={CEStyles.column_container}>
                            <Dictionary words={[
                                "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten", "zero"
                            ]} />
                        </div>
                    </div>
                </div>

                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('vocabulary-groceries')}>
                        <h2><FaChevronDown className={`chevron ${expandedDiv === 'vocabulary-groceries' ? "chevron_rotate" : ""}`} /> Vocabulary - Groceries</h2>
                    </div>
                    <div className={expandedDiv === 'vocabulary-groceries' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.column_container}>
                            <VocabularyQuiz words={[
                                "milk",
                                "bread",
                                "egg",
                                "rice",
                                "water",
                                "chicken",
                                "apple",
                                "potato",
                                "banana",
                                "salt",
                                "sugar",
                                "flour",
                                "oil",
                                "tomato",
                                "onion",
                                "beef",
                                "carrot",
                                "pasta",
                                "beans",
                                "butter",
                                "cheese",
                                "fish",
                                "cereal",
                                "orange"
                            ]} onClose={() => alert("Closing")} />
                        </div>
                        <div className={CEStyles.column_container}>
                            <Dictionary words={[
                                "milk",
                                "bread",
                                "egg",
                                "rice",
                                "water",
                                "chicken",
                                "apple",
                                "potato",
                                "banana",
                                "salt",
                                "sugar",
                                "flour",
                                "oil",
                                "tomato",
                                "onion",
                                "beef",
                                "carrot",
                                "pasta",
                                "beans",
                                "butter",
                                "cheese",
                                "fish",
                                "cereal",
                                "orange"
                            ]} />
                        </div>
                    </div>
                </div>



                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('vocabulary-body_parts')}>
                        <h2><FaChevronDown className={`chevron ${expandedDiv === 'vocabulary-body_parts' ? "chevron_rotate" : ""}`} /> Vocabulary - Body Parts</h2>
                    </div>
                    <div className={expandedDiv === 'vocabulary-body_parts' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.column_container}>
                            <VocabularyQuiz words={[
                                "head", "face", "eye", "ear", "nose", "mouth", "hand", "arm", "leg", "foot"
                            ]} onClose={() => alert("Closing")} />
                        </div>
                        <div className={CEStyles.column_container}>
                            <Dictionary words={[ "head", "face", "eye", "ear", "nose", "mouth", "hand", "arm", "leg", "foot"]} />
                        </div>
                    </div>
                </div>

                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('vocabulary-animals')}>
                        <h2><FaChevronDown className={`chevron ${expandedDiv === 'vocabulary-animals' ? "chevron_rotate" : ""}`} /> Vocabulary - Animals</h2>
                    </div>
                    <div className={expandedDiv === 'vocabulary-animals' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.column_container}>
                            <VocabularyQuiz words={[
                                "cat", "dog", "bird", "fish", "cow", "horse", "pig", "sheep", "chicken", "duck"
                            ]} onClose={() => alert("Closing")} />
                        </div>
                        <div className={CEStyles.column_container}>
                            <Dictionary words={["cat", "dog", "bird", "fish", "cow", "horse", "pig", "sheep", "chicken", "duck"]} />
                        </div>
                    </div>
                </div>



                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('vocabulary')}>
                        <h2><FaChevronDown className={`chevron ${expandedDiv === 'vocabulary' ? "chevron_rotate" : ""}`} /> Vocabulary</h2>
                    </div>
                    <div className={expandedDiv === 'vocabulary' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.table_container}>
                            <table>
                                <thead>
                                <tr className={CEStyles.vocab_table_header_row}>
                                    {tableData[0].map((header, idx) => (
                                        <th key={idx} className={GenStyles.text_umkc_blue}>
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {tableData.slice(1).map((row, rowIndex) => (
                                    <tr key={rowIndex} className={rowIndex % 2 === 0 ? CEStyles.shaded_row : ''}>
                                        {row.map((cell, cellIndex) => (
                                            <td key={cellIndex} className={cellIndex === 0 ? CEStyles.vocab_word : ''}>
                                                {cell}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CEFoundations;
