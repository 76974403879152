import React, {useEffect, useState, useRef} from "react";
import GenStyles from "./styles/GenStyles.module.css";
import { talk } from "./ALI_Ajax.js";
import TStyles from "./styles/TestStyles.module.css";
import {useUser} from "./UserContext";
import LoginModule from "./login_module";
import STTStyles from "./styles/STTStyles.module.css";
import {ALI_Recorder} from "./ALI_Recorder.js";




const TakeTest = (props) => {
    const {userId, finishedLoggingIn} = useUser();
    const [responseText, setResponseText] = useState(false);
    const [audioUrl, setAudioUrl] = useState(false);
    const [studentTextResponse, setStudentTextResponse] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [recordingUrl, setRecordingUrl] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [timeRemaining, setTimeRemaining] = useState(null);



    //USE EFFECTS
    useEffect(() => {
        console.log(recordingUrl);
        if(recordingUrl) {
            speech_to_text();
        }
    }, [recordingUrl])
    useEffect(() => {
        const handleRecordingStarted = () => {
            console.log("Recording started event received!");
            setIsRecording(true);
            setNotificationMessage("Recording: ");
        };
        const handleRecordingStopped = (event) => {
            console.log("Recording has stopped. Audio URL: ", event.detail.audioURL);
            setRecordingUrl(ALI_Recorder.processRecording());
            setIsRecording(false)
            setNotificationMessage("Processing");
        }
        const handleRecordingTick = (event) => {
            console.log("Timer Tick");
            setNotificationMessage("Recording: " + timeRemaining);
            setTimeRemaining(event.detail.timeRemaining);
        }


        document.addEventListener("recordingTick", handleRecordingTick);
        document.addEventListener("recordingStarted", handleRecordingStarted);
        document.addEventListener("recordingStopped", handleRecordingStopped);

        return () => {
            document.removeEventListener("recordingTick", handleRecordingTick);
            document.removeEventListener("recordingStarted", handleRecordingStarted);
            document.removeEventListener("recordingStopped", handleRecordingStopped);
        }
    }, []);
    useEffect(() => {

    }, [userId]);
    useEffect(() => {
        if(audioUrl) {
            console.log(audioUrl);
            let player = document.getElementById('audio_player');
            player.src = audioUrl;
            player.play();
        }
    }, [audioUrl])

    //AJAX FUNCTIONS
    const clear_session = () => {
        console.log("Sending request to clear session on the server...");
        talk("./ali_api/OpenAI_Handler.php", "CLEAR_SESSION", {pin:1492}, clear_session_handler);
    }
    const clear_session_handler = (operation, sent_data, return_data) => {
        console.log(return_data);

    }
    //This function will cause the AI to ask the student a question
    const initiate_test = () => {
        let prompt = "You are an ESL instructor giving an oral interview to a student that will be used in a" +
            "placement test in an ESL program. You don't have any idea about the student's level. You will ask" +
            "a series of questions and based on the answers, try to determine the student's level. It should feel" +
            "like a natural conversation for the student. Polite. Professional. Begin with normal social connections " +
            "and proceed to harder questions. Base your subsequent questions on the level of the student's response. " +
            "If the student responds at a very high level, try asking more challenging questions. If they don't " +
            "seem to understand, ask easier questions. Invent a name for yourself. Briefly introduce yourself to the student and ask your first question for the student. Your first question should always be to ask their name. Also... use a male name for yourself.";
        prompt = "You are a new acquaintance just trying to get to know me. Ask me a series of questions to get to know me better. Ask me one question at a time and wait for my response before asking a new question. Act like a normal human being... respond to my response and express interest before asking the next question.";

        console.log("Sending prompt to server:", prompt);

        let postData = {
            operation: "GET_TEXT_AND_SPEECH_RESPONSE",
            prompt: prompt,
            pin: "1492"
        }

        talk("./ali_api/OpenAI_Handler.php", "GET_TEXT_AND_SPEECH_RESPONSE", postData, initiate_test_handler);
    }
    const initiate_test_handler = (operation, sent_data, return_data) => {
        if(return_data['error']) {
            setResponseText(return_data['error']);
        } else {
            setResponseText(return_data['text']);
            fetch_audio(return_data['audio_filename'])
        }
        console.log(return_data);
    }
    const submit_student_response = (response_text) => {
        console.log("Sending prompt to server:", response_text);

        let postData = {
            operation: "GET_TEXT_AND_SPEECH_RESPONSE",
            prompt: response_text,
            pin: "1492"
        }

        talk("./ali_api/OpenAI_Handler.php", "GET_TEXT_AND_SPEECH_RESPONSE", postData, initiate_test_handler);
    }
    const submit_student_response_handler = () => {

    }



    const speech_to_text = () => {
        console.log("Sending prompt to server:", prompt);

        let postData = new FormData();
        postData.append("operation", "EXTRACT_TEXT_FROM_SPEECH");
        postData.append("pin", "1492");
        postData.append("student_info", JSON.stringify({
            student_id: 1234545,
            user_and_test_id: 22
        }));
        postData.append("file", ALI_Recorder.get_blob(), "recording.webm");
        talk("./ali_api/OpenAI_Handler.php", "EXTRACT_TEXT_FROM_SPEECH", postData, speech_to_text_handler);
    }
    const speech_to_text_handler = (operation, sent_data, return_data) => {
        if(return_data['error']) {
            setStudentTextResponse("ERROR: " + return_data['error']);
        } else {
            //setStudentTextResponse(return_data['response']);
            submit_student_response(return_data['response'])
        }

        console.log(return_data);
    }





    //OTHER FUNCTIONS
    const fetch_audio = async (filename) => {
        console.log(filename);

        // Generate the URL for the PHP script that streams audio
        // const url = '/ali_api/ALIAudioStreamer.php?filename=' + encodeURIComponent(filename) + "&t=" + Date.now();
        const url = '/ali_api/ALIAudioStreamer.php?filename=' + filename + "&t=" + Date.now();
        setAudioUrl(url);
    };
    const initialize_recorder = () => {
        if(isRecording) return;
        ALI_Recorder.init();
        ALI_Recorder.startRecording(20);
        setNotificationMessage('Recording');
    }
    const stop_recording = () => {
        if(!isRecording) return;
        ALI_Recorder.stopRecording();
        setNotificationMessage("Answering...");
    }
    const handle_examinee_finished_talking = () => {
        initialize_recorder();
    }



    //RENDERING
    return (
        <>
            {
                finishedLoggingIn ?
                    <>

                        {userId ?
                            <div className={TStyles.container}>
                                <h1 className={TStyles.title}>Test Name</h1>
                                <h3>Dummy Test Loop</h3>
                                <div>{responseText}</div>
                                <button
                                    onClick={initiate_test}
                                    className={STTStyles.stt_button}
                                >Start Conversation</button>


                                <div>
                                    {audioUrl && (
                                        <audio
                                            controls
                                            autoPlay
                                            id="audio_player"
                                            onEnded={handle_examinee_finished_talking}
                                        >
                                            <source src={audioUrl} type="audio/mpeg" />
                                            Your browser does not support the audio tag.
                                        </audio>
                                    )}
                                </div>
                                <div className={notificationMessage === 'Processing' ? TStyles.processing_message : TStyles.student_recording_message}>
                                    {notificationMessage} {timeRemaining ? timeRemaining : ""}
                                </div>
                                <div className={GenStyles.hidden}>
                                    <div>
                                        <button
                                            className={STTStyles.stt_button}
                                            onClick={initialize_recorder}
                                        >
                                            Start Recording Answer
                                        </button>
                                        <button
                                            className={STTStyles.stt_button}
                                            onClick={stop_recording}
                                        >
                                            Stop Recording
                                        </button>
                                    </div>
                                    <div>
                                        <textarea
                                            onChange={(event) => setStudentTextResponse(event.target.value)}
                                            value={studentTextResponse}
                                        >

                                        </textarea>
                                    </div>
                                    <div>
                                        <button
                                            className={STTStyles.stt_button}
                                            onClick={() => submit_student_response(studentTextResponse)}
                                        >Submit Response</button>
                                    </div>
                                </div>
                                <div className={TStyles.container}>
                                    <button
                                        className={STTStyles.stt_button}
                                        onClick={clear_session}
                                    >
                                        Clear Session and Start Over
                                    </button>
                                </div>
                            </div>
                            : <LoginModule/>}
                    </> :
                    <div>loading</div>
            }
        </>
    )
}

export default TakeTest;
