import React, {useEffect, useState, useRef} from "react";
import GenStyles from "./styles/GenStyles.module.css";
import CEStyles from "./styles/CommunityEnglishStyles.module.css";
import {talk} from "./ALI_Ajax.js";
import {useUser} from "./UserContext";
import Foundations from "./images/foundations.jpg";
import Pathways from "./images/pathways.jpg";
import Horizons from "./images/horizons.jpg";
import Competencies from "./files/CE_Core_Competencies.pdf";


import LoginModule from "./login_module";

const CommunityEnglish = (props) => {
    const nav_to = (which) => {
        switch(which) {
            case 'foundations':
                document.location.href='ce-foundations';
                break;
            case 'pathways':
                document.location.href='ce-pathways';
                break;
            case 'horizons':
                document.location.href='ce-horizons';
                break;
        }
    }

    return (
        <>
            {/* Header */}
            <div className={GenStyles.container} style={{marginTop: "80px"}} id="showcase">
                <h1 className={GenStyles.jumbo}><b>ALI Community English</b></h1>
                <h1 className={`${GenStyles.xxxlarge} ${GenStyles.text_umkc_blue}`}><b>Home</b></h1>
                <hr style={{width: "50px", border: "5px solid #ffd52f"}} className={GenStyles.round}/>
            </div>
            <div className={CEStyles.container}>
                <div className={`${CEStyles.group_item} ${CEStyles.foundations}`} onClick={() => nav_to('foundations')}>
                    <img src={Foundations} className={CEStyles.group_image}/>
                    <div className={CEStyles.group_title}>Foundations</div>
                </div>
                <div className={`${CEStyles.group_item} ${CEStyles.pathways}`} onClick={() => nav_to('pathways')}>
                    <img src={Pathways} className={CEStyles.group_image}/>
                    <div className={CEStyles.group_title}>Pathways</div>
                </div>
                <div className={`${CEStyles.group_item} ${CEStyles.horizons}`} onClick={() => nav_to('horizons')}>
                    <img src={Horizons} className={CEStyles.group_image}/>
                    <div className={CEStyles.group_title}>Horizons</div>
                </div>
            </div>
        </>
    );
}

export default CommunityEnglish;
