import React, {useEffect, useState, useRef} from "react";
import GenStyles from "./styles/GenStyles.module.css";
import CEStyles from "./styles/CommunityEnglishStyles.module.css";
import {talk} from "./ALI_Ajax.js";
import {useUser} from "./UserContext";
import Foundations from "./images/foundations.jpg";
import Pathways from "./images/pathways.jpg";
import Horizons from "./images/horizons.jpg";

import LoginModule from "./login_module";
import {FaChevronDown} from "react-icons/fa";
import Greetings from "./files/Greetings_and_Meetings.pdf";
import Slideshow from "./slideshow";
import Dictionary from "./Dictionary";
import VocabularyQuiz from "./VocabularyQuiz";


const CEPathways = (props) => {

    const tableData = [
        // Header row
        ["Word", "Part of Speech", "Category"],
        // Data rows
        ["rabbit", "noun", "animals"],
        ["turtle", "noun", "animals"],
        ["frog", "noun", "animals"],
        ["bee", "noun", "animals"],
        ["ant", "noun", "animals"],
        ["elephant", "noun", "animals"],
        ["lion", "noun", "animals"],
        ["tiger", "noun", "animals"],
        ["bear", "noun", "animals"],
        ["monkey", "noun", "animals"],
        ["giraffe", "noun", "animals"],
        ["zebra", "noun", "animals"],
        ["kangaroo", "noun", "animals"],
        ["dolphin", "noun", "animals"],
        ["shark", "noun", "animals"],
        ["whale", "noun", "animals"],
        ["crab", "noun", "animals"],
        ["snail", "noun", "animals"],
        ["squirrel", "noun", "animals"],
        ["shoulder", "noun", "body parts"],
        ["knee", "noun", "body parts"],
        ["elbow", "noun", "body parts"],
        ["finger", "noun", "body parts"],
        ["toe", "noun", "body parts"],
        ["back", "noun", "body parts"],
        ["chest", "noun", "body parts"],
        ["stomach", "noun", "body parts"],
        ["carrot", "noun", "food"],
        ["potato", "noun", "food"],
        ["tomato", "noun", "food"],
        ["cucumber", "noun", "food"],
        ["onion", "noun", "food"],
        ["garlic", "noun", "food"],
        ["pepper", "noun", "food"],
        ["lettuce", "noun", "food"],
        ["spinach", "noun", "food"],
        ["cheese", "noun", "food"],
        ["pasta", "noun", "food"],
        ["cake", "noun", "food"],
        ["cookie", "noun", "food"],
        ["chocolate", "noun", "food"],
        ["salad", "noun", "food"],
        ["aunt", "noun", "family"],
        ["uncle", "noun", "family"],
        ["cousin", "noun", "family"],
        ["nephew", "noun", "family"],
        ["niece", "noun", "family"],
        ["daughter", "noun", "family"],
        ["son", "noun", "family"],
        ["gloves", "noun", "clothing"],
        ["scarf", "noun", "clothing"],
        ["belt", "noun", "clothing"],
        ["sunglasses", "noun", "clothing"],
        ["boots", "noun", "clothing"],
        ["shorts", "noun", "clothing"],
        ["sweater", "noun", "clothing"],
        ["underwear", "noun", "clothing"],
        ["office", "noun", "places"],
        ["church", "noun", "places"],
        ["post office", "noun", "places"],
        ["stadium", "noun", "places"],
        ["cinema", "noun", "places"],
        ["museum", "noun", "places"],
        ["zoo", "noun", "places"],
        ["beach", "noun", "places"],
        ["lamp", "noun", "furniture"],
        ["cabinet", "noun", "furniture"],
        ["shelf", "noun", "furniture"],
        ["rug", "noun", "furniture"],
        ["mirror", "noun", "furniture"],
        ["dresser", "noun", "furniture"],
        ["stool", "noun", "furniture"],
        ["bookshelf", "noun", "furniture"],
        ["truck", "noun", "vehicles"],
        ["van", "noun", "vehicles"],
        ["train", "noun", "vehicles"],
        ["airplane", "noun", "vehicles"],
        ["boat", "noun", "vehicles"],
        ["taxi", "noun", "vehicles"],
        ["scooter", "noun", "vehicles"],
        ["red", "noun", "colors"],
        ["blue", "noun", "colors"],
        ["green", "noun", "colors"],
        ["yellow", "noun", "colors"],
        ["orange", "noun", "colors"],
        ["purple", "noun", "colors"],
        ["black", "noun", "colors"],
        ["white", "noun", "colors"],
        ["brown", "noun", "colors"],
        ["sun", "noun", "weather"],
        ["rain", "noun", "weather"],
        ["snow", "noun", "weather"],
        ["wind", "noun", "weather"],
        ["cloud", "noun", "weather"],
        ["book", "noun", "school items"],
        ["pencil", "noun", "school items"],
        ["pen", "noun", "school items"],
        ["paper", "noun", "school items"],
        ["eraser", "noun", "school items"],
        ["backpack", "noun", "school items"],
        ["learn", "verb", ""],
        ["buy", "verb", ""],
        ["sell", "verb", ""],
        ["cook", "verb", ""],
        ["drive", "verb", ""],
        ["fly", "verb", ""],
        ["think", "verb", ""],
        ["feel", "verb", ""],
        ["say", "verb", ""],
        ["call", "verb", ""],
        ["help", "verb", ""],
        ["open", "verb", ""],
        ["close", "verb", ""],
        ["stop", "verb", ""],
        ["start", "verb", ""],
        ["begin", "verb", ""],
        ["finish", "verb", ""],
        ["build", "verb", ""],
        ["create", "verb", ""],
        ["live", "verb", ""],
        ["love", "verb", ""],
        ["watch", "verb", ""],
        ["sit", "verb", ""],
        ["stand", "verb", ""],
        ["smile", "verb", ""],
        ["cry", "verb", ""],
        ["try", "verb", ""],
        ["use", "verb", ""],
        ["wait", "verb", ""],
        ["give", "verb", ""],
        ["take", "verb", ""],
        ["bring", "verb", ""],
        ["look", "verb", ""],
        ["beautiful", "adjective", ""],
        ["ugly", "adjective", ""],
        ["funny", "adjective", ""],
        ["serious", "adjective", ""],
        ["loud", "adjective", ""],
        ["quiet", "adjective", ""],
        ["rich", "adjective", ""],
        ["poor", "adjective", ""],
        ["clean", "adjective", ""],
        ["dirty", "adjective", ""],
        ["strong", "adjective", ""],
        ["weak", "adjective", ""],
        ["light", "adjective", ""],
        ["dark", "adjective", ""],
        ["soft", "adjective", ""],
        ["hard", "adjective", ""],
        ["full", "adjective", ""],
        ["empty", "adjective", ""],
        ["bright", "adjective", ""],
        ["dull", "adjective", ""],
        ["expensive", "adjective", ""],
        ["cheap", "adjective", ""],
        ["very", "adverb", ""],
        ["really", "adverb", ""],
        ["always", "adverb", ""],
        ["never", "adverb", ""],
        ["often", "adverb", ""],
        ["sometimes", "adverb", ""],
        ["now", "adverb", ""],
        ["then", "adverb", ""],
        ["here", "adverb", ""],
        ["there", "adverb", ""],
        ["quickly", "adverb", ""],
        ["slowly", "adverb", ""],
        ["under", "preposition", ""],
        ["over", "preposition", ""],
        ["between", "preposition", ""],
        ["among", "preposition", ""],
        ["through", "preposition", ""],
        ["during", "preposition", ""],
        ["my", "pronoun", ""],
        ["your", "pronoun", ""],
        ["his", "pronoun", ""],
        ["her", "pronoun", ""],
        ["our", "pronoun", ""],
        ["their", "pronoun", ""],
        ["mine", "pronoun", ""],
        ["yours", "pronoun", ""],
        ["myself", "pronoun", ""],
        ["although", "conjunction", ""],
        ["since", "conjunction", ""],
        ["while", "conjunction", ""],
        ["however", "conjunction", ""],
        ["each", "determiner", ""],
        ["every", "determiner", ""],
        ["many", "determiner", ""],
        ["few", "determiner", ""],
        ["eleven", "number", ""],
        ["twelve", "number", ""],
        ["thirteen", "number", ""],
        ["fourteen", "number", ""],
        ["fifteen", "number", ""],
        ["sixteen", "number", ""],
        ["seventeen", "number", ""],
        ["eighteen", "number", ""],
        ["nineteen", "number", ""],
        ["twenty", "number", ""]
    ];

    const [expandedDiv, setExpandedDiv] = useState(null);


    const set_open_div = (which_div) => {
        //If clicking the same div a second time, close it
        if(which_div === expandedDiv) {
            setExpandedDiv(null);
        } else {
            setExpandedDiv(which_div);
        }
    }


    
    return (
        <>
            {/* Header */}
            <div className={GenStyles.container} style={{marginTop: "80px"}} id="showcase">
                <h1 className={GenStyles.jumbo}><b>ALI Community English</b></h1>
                <h1 className={`${GenStyles.xxxlarge} ${GenStyles.text_umkc_blue}`}><b>CE Pathways</b></h1>
                <hr style={{width: "50px", border: "5px solid #ffd52f"}} className={GenStyles.round}/>
            </div>
            <div className={GenStyles.container}>
                <div className={`${CEStyles.group_item} ${CEStyles.pathways}`} >
                    <img src={Pathways} className={CEStyles.group_image}/>
                    <div className={CEStyles.group_title}>Pathways</div>
                </div>

                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('greetings')}>
                        <h2><FaChevronDown className={`chevron ${expandedDiv === 'greetings' ? "chevron_rotate" : ""}`} /> Greetings</h2>
                    </div>
                    <div className={expandedDiv === 'greetings' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.column_container}>
                            <Slideshow slides={
                                [
                                    "hello_male",
                                    "hi_female",
                                    "how_are_you_male",
                                    "fine_how_are_you_female",
                                    "good_thank_you_male",
                                    "whats_your_name_female",
                                    "my_name_is_ben_male",
                                    "whats_your_name_male",
                                    "my_name_is_mary_female",
                                    "nice_to_meet_you_mary_male",
                                    "nice_to_meet_you_too_ben_female",
                                    "how_are_you_variations",
                                    "hello_variations"
                                ]}/>

                            <iframe
                                src={Greetings}
                                width="100%"
                                height="600px"
                                title="PDF Viewer"
                                style={{ border: 'none' }}
                            />
                        </div>
                    </div>
                </div>

                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('vocabulary-groceries')}>
                        <h2><FaChevronDown className={`chevron ${expandedDiv === 'vocabulary-groceries' ? "chevron_rotate" : ""}`} /> Vocabulary - Groceries</h2>
                    </div>
                    <div className={expandedDiv === 'vocabulary-groceries' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.column_container}>
                            <VocabularyQuiz words={[
                                "apple", "banana", "beans", "beef", "bread", "butter", "carrot", "celery",
                                "cereal", "cheese", "chicken", "corn", "crab", "egg", "fish", "flour",
                                "fruit", "grape", "lemon", "lime", "mango", "meat", "milk", "oil", "onion",
                                "orange", "pasta", "peach", "pear", "pineapple", "plum", "potato", "rice",
                                "salt", "strawberry", "sugar", "tomato", "water", "watermelon", "zucchini",
                                "bell pepper", "blueberry", "broccoli", "cream", "cucumber", "garlic",
                                "ice cream", "lettuce", "oatmeal", "pork", "shrimp", "spinach", "tofu",
                                "tortilla", "yogurt"
                            ]} onClose={() => alert("Closing")} />
                        </div>
                        <div className={CEStyles.column_container}>
                            <Dictionary words={[
                                "apple", "banana", "beans", "beef", "bread", "butter", "carrot", "celery",
                                "cereal", "cheese", "chicken", "corn", "crab", "egg", "fish", "flour",
                                "fruit", "grape", "lemon", "lime", "mango", "meat", "milk", "oil", "onion",
                                "orange", "pasta", "peach", "pear", "pineapple", "plum", "potato", "rice",
                                "salt", "strawberry", "sugar", "tomato", "water", "watermelon", "zucchini",
                                "bell pepper", "blueberry", "broccoli", "cream", "cucumber", "garlic",
                                "ice cream", "lettuce", "oatmeal", "pork", "shrimp", "spinach", "tofu",
                                "tortilla", "yogurt"
                            ]} />
                        </div>
                    </div>
                </div>


                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('vocabulary-body_parts')}>
                        <h2><FaChevronDown className={`chevron ${expandedDiv === 'vocabulary-body_parts' ? "chevron_rotate" : ""}`} /> Vocabulary - Body Parts</h2>
                    </div>
                    <div className={expandedDiv === 'vocabulary-body_parts' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.column_container}>
                            <VocabularyQuiz words={[
                                "head", "face", "eye", "ear", "nose", "mouth", "hand", "arm", "leg", "foot",
                                "shoulder", "knee", "elbow", "finger", "toe", "back", "chest", "stomach"
                            ]} onClose={() => alert("Closing")} />
                        </div>
                        <div className={CEStyles.column_container}>
                            <Dictionary words={[
                                "head", "face", "eye", "ear", "nose", "mouth", "hand", "arm", "leg", "foot",
                                "shoulder", "knee", "elbow", "finger", "toe", "back", "chest", "stomach"
                            ]} />
                        </div>
                    </div>
                </div>

                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('vocabulary-animals')}>
                        <h2><FaChevronDown className={`chevron ${expandedDiv === 'vocabulary-animals' ? "chevron_rotate" : ""}`} /> Vocabulary - Animals</h2>
                    </div>
                    <div className={expandedDiv === 'vocabulary-animals' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.column_container}>
                            <VocabularyQuiz words={[
                                "cat", "dog", "bird", "fish", "cow", "horse", "pig", "sheep", "chicken", "duck",
                                "rabbit", "turtle", "frog", "bee", "ant", "elephant", "lion", "tiger", "bear", "monkey"
                            ]} onClose={() => alert("Closing")} />
                        </div>
                        <div className={CEStyles.column_container}>
                            <Dictionary words={[
                                "cat", "dog", "bird", "fish", "cow", "horse", "pig", "sheep", "chicken", "duck",
                                "rabbit", "turtle", "frog", "bee", "ant", "elephant", "lion", "tiger", "bear", "monkey"
                            ]} />
                        </div>
                    </div>
                </div>

                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('vocabulary')}>
                        <h2><FaChevronDown className={`chevron ${expandedDiv === 'vocabulary' ? "chevron_rotate" : ""}`} /> Vocabulary</h2>
                    </div>
                    <div className={expandedDiv === 'vocabulary' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.table_container}>
                            <table>
                                <thead>
                                <tr className={CEStyles.vocab_table_header_row}>
                                    {tableData[0].map((header, idx) => (
                                        <th key={idx} className={GenStyles.text_umkc_blue}>
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {tableData.slice(1).map((row, rowIndex) => (
                                    <tr key={rowIndex} className={rowIndex % 2 === 0 ? CEStyles.shaded_row : ''}>
                                        {row.map((cell, cellIndex) => (
                                            <td key={cellIndex} className={cellIndex === 0 ? CEStyles.vocab_word : ''}>
                                                {cell}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CEPathways;
