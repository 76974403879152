import React, {useEffect, useState, useRef} from "react";
import GenStyles from "./styles/GenStyles.module.css";
import CEStyles from "./styles/CommunityEnglishStyles.module.css";
import {talk} from "./ALI_Ajax.js";
import {useUser} from "./UserContext";
import Foundations from "./images/foundations.jpg";
import Pathways from "./images/pathways.jpg";
import Horizons from "./images/horizons.jpg";
import Greetings from "./files/Greetings_and_Meetings.pdf";


import LoginModule from "./login_module";
import { FaChevronDown } from "react-icons/fa";
import Slideshow from "./slideshow";
import Dictionary from "./Dictionary";
import VocabularyQuiz from "./VocabularyQuiz";


const CEHorizons = (props) => {

    const tableData = [
        // Header row
        ["Word", "Part of Speech", "Category"],
        // Data rows
        ["deer", "noun", "animals"],
        ["wolf", "noun", "animals"],
        ["fox", "noun", "animals"],
        ["owl", "noun", "animals"],
        ["eagle", "noun", "animals"],
        ["penguin", "noun", "animals"],
        ["leopard", "noun", "animals"],
        ["camel", "noun", "animals"],
        ["panda", "noun", "animals"],
        ["koala", "noun", "animals"],
        ["hair", "noun", "body parts"],
        ["chin", "noun", "body parts"],
        ["cheek", "noun", "body parts"],
        ["lips", "noun", "body parts"],
        ["tooth", "noun", "body parts"],
        ["tongue", "noun", "body parts"],
        ["grape", "noun", "food"],
        ["strawberry", "noun", "food"],
        ["lemon", "noun", "food"],
        ["lime", "noun", "food"],
        ["watermelon", "noun", "food"],
        ["pineapple", "noun", "food"],
        ["mango", "noun", "food"],
        ["peach", "noun", "food"],
        ["plum", "noun", "food"],
        ["cherry", "noun", "food"],
        ["husband", "noun", "family"],
        ["wife", "noun", "family"],
        ["parent", "noun", "family"],
        ["child", "noun", "family"],
        ["relative", "noun", "family"],
        ["blouse", "noun", "clothing"],
        ["suit", "noun", "clothing"],
        ["vest", "noun", "clothing"],
        ["cardigan", "noun", "clothing"],
        ["pajamas", "noun", "clothing"],
        ["sneakers", "noun", "clothing"],
        ["airport", "noun", "places"],
        ["station", "noun", "places"],
        ["bakery", "noun", "places"],
        ["pharmacy", "noun", "places"],
        ["cafe", "noun", "places"],
        ["hotel", "noun", "places"],
        ["armchair", "noun", "furniture"],
        ["couch", "noun", "furniture"],
        ["wardrobe", "noun", "furniture"],
        ["drawer", "noun", "furniture"],
        ["motorcycle", "noun", "vehicles"],
        ["subway", "noun", "vehicles"],
        ["tram", "noun", "vehicles"],
        ["ferry", "noun", "vehicles"],
        ["pink", "noun", "colors"],
        ["gray", "noun", "colors"],
        ["violet", "noun", "colors"],
        ["turquoise", "noun", "colors"],
        ["storm", "noun", "weather"],
        ["fog", "noun", "weather"],
        ["thunder", "noun", "weather"],
        ["notebook", "noun", "school items"],
        ["marker", "noun", "school items"],
        ["crayon", "noun", "school items"],
        ["teacher", "noun", "occupations"],
        ["doctor", "noun", "occupations"],
        ["nurse", "noun", "occupations"],
        ["engineer", "noun", "occupations"],
        ["lawyer", "noun", "occupations"],
        ["farmer", "noun", "occupations"],
        ["chef", "noun", "occupations"],
        ["waiter", "noun", "occupations"],
        ["firefighter", "noun", "occupations"],
        ["scientist", "noun", "occupations"],
        ["mountain", "noun", "nature"],
        ["river", "noun", "nature"],
        ["lake", "noun", "nature"],
        ["forest", "noun", "nature"],
        ["desert", "noun", "nature"],
        ["valley", "noun", "nature"],
        ["medicine", "noun", "health"],
        ["clinic", "noun", "health"],
        ["symptom", "noun", "health"],
        ["diagnosis", "noun", "health"],
        ["treatment", "noun", "health"],
        ["freedom", "noun", "abstract"],
        ["responsibility", "noun", "abstract"],
        ["opportunity", "noun", "abstract"],
        ["challenge", "noun", "abstract"],
        ["success", "noun", "abstract"],
        ["failure", "noun", "abstract"],
        ["experience", "noun", "abstract"],
        ["knowledge", "noun", "abstract"],
        ["computer", "noun", "technology"],
        ["internet", "noun", "technology"],
        ["smartphone", "noun", "technology"],
        ["email", "noun", "technology"],
        ["website", "noun", "technology"],
        ["software", "noun", "technology"],
        ["hardware", "noun", "technology"],
        ["keyboard", "noun", "technology"],
        ["printer", "noun", "technology"],
        ["camera", "noun", "technology"],
        ["consider", "verb", ""],
        ["explain", "verb", ""],
        ["describe", "verb", ""],
        ["improve", "verb", ""],
        ["develop", "verb", ""],
        ["manage", "verb", ""],
        ["prepare", "verb", ""],
        ["suggest", "verb", ""],
        ["decide", "verb", ""],
        ["discuss", "verb", ""],
        ["plan", "verb", ""],
        ["recommend", "verb", ""],
        ["imagine", "verb", ""],
        ["believe", "verb", ""],
        ["include", "verb", ""],
        ["involve", "verb", ""],
        ["avoid", "verb", ""],
        ["reduce", "verb", ""],
        ["increase", "verb", ""],
        ["provide", "verb", ""],
        ["examine", "verb", ""],
        ["explore", "verb", ""],
        ["discover", "verb", ""],
        ["maintain", "verb", ""],
        ["organize", "verb", ""],
        ["support", "verb", ""],
        ["investigate", "verb", ""],
        ["solve", "verb", ""],
        ["achieve", "verb", ""],
        ["handle", "verb", ""],
        ["evaluate", "verb", ""],
        ["measure", "verb", ""],
        ["express", "verb", ""],
        ["communicate", "verb", ""],
        ["contribute", "verb", ""],
        ["interesting", "adjective", ""],
        ["boring", "adjective", ""],
        ["complex", "adjective", ""],
        ["simple", "adjective", ""],
        ["modern", "adjective", ""],
        ["traditional", "adjective", ""],
        ["efficient", "adjective", ""],
        ["effective", "adjective", ""],
        ["valuable", "adjective", ""],
        ["important", "adjective", ""],
        ["necessary", "adjective", ""],
        ["popular", "adjective", ""],
        ["common", "adjective", ""],
        ["unique", "adjective", ""],
        ["reliable", "adjective", ""],
        ["creative", "adjective", ""],
        ["flexible", "adjective", ""],
        ["curious", "adjective", ""],
        ["practical", "adjective", ""],
        ["delicious", "adjective", ""],
        ["advanced", "adjective", ""],
        ["challenging", "adjective", ""],
        ["enthusiastic", "adjective", ""],
        ["exceptional", "adjective", ""],
        ["motivated", "adjective", ""],
        ["optimistic", "adjective", ""],
        ["pessimistic", "adjective", ""],
        ["remarkable", "adjective", ""],
        ["significant", "adjective", ""],
        ["vibrant", "adjective", ""],
        ["versatile", "adjective", ""],
        ["dynamic", "adjective", ""],
        ["ambitious", "adjective", ""],
        ["frequently", "adverb", ""],
        ["occasionally", "adverb", ""],
        ["gradually", "adverb", ""],
        ["suddenly", "adverb", ""],
        ["easily", "adverb", ""],
        ["simply", "adverb", ""],
        ["seriously", "adverb", ""],
        ["clearly", "adverb", ""],
        ["ultimately", "adverb", ""],
        ["finally", "adverb", ""],
        ["before", "preposition", ""],
        ["after", "preposition", ""],
        ["around", "preposition", ""],
        ["beside", "preposition", ""],
        ["against", "preposition", ""],
        ["everyone", "pronoun", ""],
        ["someone", "pronoun", ""],
        ["anybody", "pronoun", ""],
        ["nobody", "pronoun", ""],
        ["though", "conjunction", ""],
        ["unless", "conjunction", ""],
        ["whereas", "conjunction", ""],
        ["another", "determiner", ""],
        ["other", "determiner", ""],
        ["thirty", "number", ""],
        ["forty", "number", ""],
        ["fifty", "number", ""],
        ["sixty", "number", ""],
        ["seventy", "number", ""],
        ["eighty", "number", ""],
        ["ninety", "number", ""],
        ["hundred", "number", ""]
    ];
    const [expandedDiv, setExpandedDiv] = useState(null);


    const set_open_div = (which_div) => {
        //If clicking the same div a second time, close it
        if(which_div === expandedDiv) {
            setExpandedDiv(null);
        } else {
            setExpandedDiv(which_div);
        }
    }

    
    return (
        <>
            {/* Header */}
            <div className={GenStyles.container} style={{marginTop: "80px"}} id="showcase">
                <h1 className={GenStyles.jumbo}><b>ALI Community English</b></h1>
                <h1 className={`${GenStyles.xxxlarge} ${GenStyles.text_umkc_blue}`}><b>CE Horizons</b></h1>
                <hr style={{width: "50px", border: "5px solid #ffd52f"}} className={GenStyles.round}/>
            </div>
            <div className={GenStyles.container}>
                <div className={`${CEStyles.group_item} ${CEStyles.horizons}`} >
                    <img src={Horizons} className={CEStyles.group_image}/>
                    <div className={CEStyles.group_title}>Horizons</div>
                </div>

                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('greetings')}>
                        <h2><FaChevronDown className={`chevron ${expandedDiv === 'greetings' ? "chevron_rotate" : ""}`} /> Greetings</h2>
                    </div>
                    <div className={expandedDiv === 'greetings' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.column_container}>
                            <Slideshow slides={
                                [
                                    "hello_male",
                                    "hi_female",
                                    "how_are_you_male",
                                    "fine_how_are_you_female",
                                    "good_thank_you_male",
                                    "whats_your_name_female",
                                    "my_name_is_ben_male",
                                    "whats_your_name_male",
                                    "my_name_is_mary_female",
                                    "nice_to_meet_you_mary_male",
                                    "nice_to_meet_you_too_ben_female",
                                    "how_are_you_variations",
                                    "hello_variations"
                                ]}/>

                            <iframe
                                src={Greetings}
                                width="100%"
                                height="600px"
                                title="PDF Viewer"
                                style={{ border: 'none' }}
                            />
                        </div>
                    </div>
                </div>

                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('vocabulary-groceries')}>
                        <h2><FaChevronDown className={`chevron ${expandedDiv === 'vocabulary-groceries' ? "chevron_rotate" : ""}`} /> Vocabulary - Groceries</h2>
                    </div>
                    <div className={expandedDiv === 'vocabulary-groceries' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.column_container}>
                            <VocabularyQuiz words={[
                                "apple", "banana", "beans", "beef", "bread", "butter", "carrot", "celery",
                                "cereal", "cheese", "chicken", "corn", "crab", "egg", "fish", "flour",
                                "fruit", "grape", "lemon", "lime", "mango", "meat", "milk", "oil", "onion",
                                "orange", "pasta", "peach", "pear", "pineapple", "plum", "potato", "rice",
                                "salt", "strawberry", "sugar", "tomato", "water", "watermelon", "zucchini",
                                "bell pepper", "blueberry", "broccoli", "cream", "cucumber", "garlic",
                                "ice cream", "lettuce", "oatmeal", "pork", "shrimp", "spinach", "tofu",
                                "tortilla", "yogurt"
                            ]} onClose={() => alert("Closing")} />
                        </div>
                        <div className={CEStyles.column_container}>
                            <Dictionary words={[
                                "apple", "banana", "beans", "beef", "bread", "butter", "carrot", "celery",
                                "cereal", "cheese", "chicken", "corn", "crab", "egg", "fish", "flour",
                                "fruit", "grape", "lemon", "lime", "mango", "meat", "milk", "oil", "onion",
                                "orange", "pasta", "peach", "pear", "pineapple", "plum", "potato", "rice",
                                "salt", "strawberry", "sugar", "tomato", "water", "watermelon", "zucchini",
                                "bell pepper", "blueberry", "broccoli", "cream", "cucumber", "garlic",
                                "ice cream", "lettuce", "oatmeal", "pork", "shrimp", "spinach", "tofu",
                                "tortilla", "yogurt"
                            ]} />
                        </div>
                    </div>
                </div>

                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('vocabulary-body_parts')}>
                        <h2><FaChevronDown className={`chevron ${expandedDiv === 'vocabulary-body_parts' ? "chevron_rotate" : ""}`} /> Vocabulary - Body Parts</h2>
                    </div>
                    <div className={expandedDiv === 'vocabulary-body_parts' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.column_container}>
                            <VocabularyQuiz words={[
                                "head", "face", "eye", "ear", "nose", "mouth", "hand", "arm", "leg", "foot",
                                "shoulder", "knee", "elbow", "finger", "toe", "back", "chest", "stomach",
                                "hair", "chin", "cheek", "lips", "tooth", "tongue"
                            ]} onClose={() => alert("Closing")} />
                        </div>
                        <div className={CEStyles.column_container}>
                            <Dictionary words={[ "head", "face", "eye", "ear", "nose", "mouth", "hand", "arm", "leg", "foot",
                                "shoulder", "knee", "elbow", "finger", "toe", "back", "chest", "stomach",
                                "hair", "chin", "cheek", "lips", "tooth", "tongue"
                            ]} />
                        </div>
                    </div>
                </div>

                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('vocabulary-animals')}>
                        <h2><FaChevronDown className={`chevron ${expandedDiv === 'vocabulary-animals' ? "chevron_rotate" : ""}`} /> Vocabulary - Animals</h2>
                    </div>
                    <div className={expandedDiv === 'vocabulary-animals' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.column_container}>
                            <VocabularyQuiz words={[
                                "cat", "dog", "bird", "fish", "cow", "horse", "pig", "sheep", "chicken", "duck",
                                "rabbit", "turtle", "frog", "bee", "ant", "elephant", "lion", "tiger", "bear", "monkey",
                                "deer", "wolf", "fox", "owl", "eagle", "penguin", "leopard", "camel", "panda", "koala"
                            ]} onClose={() => alert("Closing")} />
                        </div>
                        <div className={CEStyles.column_container}>
                            <Dictionary words={[
                                "cat", "dog", "bird", "fish", "cow", "horse", "pig", "sheep", "chicken", "duck",
                                "rabbit", "turtle", "frog", "bee", "ant", "elephant", "lion", "tiger", "bear", "monkey",
                                "deer", "wolf", "fox", "owl", "eagle", "penguin", "leopard", "camel", "panda", "koala"
                            ]} />
                        </div>
                    </div>
                </div>


                <div className={CEStyles.section_holder}>
                    <div className={CEStyles.section_header} onClick={() => set_open_div('vocabulary')}>
                        <h2><FaChevronDown className={`chevron ${expandedDiv === 'vocabulary' ? "chevron_rotate" : ""}`} /> Vocabulary</h2>
                    </div>
                    <div className={expandedDiv === 'vocabulary' ? CEStyles.section_content : GenStyles.hidden}>
                        <div className={CEStyles.table_container}>
                            <table>
                                <thead>
                                <tr className={CEStyles.vocab_table_header_row}>
                                    {tableData[0].map((header, idx) => (
                                        <th key={idx} className={GenStyles.text_umkc_blue}>
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {tableData.slice(1).map((row, rowIndex) => (
                                    <tr key={rowIndex} className={rowIndex % 2 === 0 ? CEStyles.shaded_row : ''}>
                                        {row.map((cell, cellIndex) => (
                                            <td key={cellIndex} className={cellIndex === 0 ? CEStyles.vocab_word : ''}>
                                                {cell}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CEHorizons;
