import React, {useEffect, useState} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {useUser} from "./UserContext";
import Layout from "./layout";
import Home from "./home";
import BasicChat from "./basic_chat";
import TextToSpeech from "./text_to_speech";
import SpeechToText from "./speech_to_text";
import LoginPage from "./login_module";
import LogoutPage from "./logout_page";
import ExamineeManager from "./examinee_manager";
import DummyTextbook from "./textbook";
import ResultsManager from "./results_manager";
import TakeTest from "./take_test";
import Admin from "./admin";
import CommunityEnglish from "./community_english";
import CEFoundations from "./ce_foundations";
import CEPathways from "./ce_pathways";
import CEHorizons from "./ce_horizons";
import Dictionary from "./Dictionary";
import DictionaryBuilder from "./DictionaryBuilder";


function App() {
    //DATA
    const {check_login_status} = useUser();

    //USE EFFECTS
    useEffect(() => {
        check_login_status();
    }, []);

    //RENDERING
    return (
        <BrowserRouter basename="/">
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="basic-chat" element={<BasicChat />} />
                    <Route path="tts" element={<TextToSpeech />} />
                    <Route path="stt" element={<SpeechToText />} />
                    <Route path="login" element={<LoginPage />} />
                    <Route path="logout" element={<LogoutPage />} />
                    <Route path="examinees" element={<ExamineeManager />} />
                    <Route path="results" element={<ResultsManager />} />
                    <Route path="take_test" element={<TakeTest />} />
                    <Route path="admin" element={<Admin />} />
                    <Route path="dummy-textbook" element={<DummyTextbook />} />
                    <Route path="community-english" element={<CommunityEnglish />} />
                    <Route path="ce-foundations" element={<CEFoundations />} />
                    <Route path="ce-pathways" element={<CEPathways />} />
                    <Route path="ce-horizons" element={<CEHorizons />} />
                    <Route path="dictionary" element={<Dictionary />} />
                    <Route path="dictionary-builder" element={<DictionaryBuilder />} />

                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
