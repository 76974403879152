import React, { useState, useEffect } from 'react';
import DStyles from './styles/DictionaryStyles.module.css';

const VocabularyQuiz = ({ words, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [options, setOptions] = useState([]);
    const [correctWord, setCorrectWord] = useState('');
    const [score, setScore] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [currentAudio, setCurrentAudio] = useState(null);
    const [disabledImages, setDisabledImages] = useState(new Set());
    const [flashEffect, setFlashEffect] = useState('');
    const [incorrectAttempted, setIncorrectAttempted] = useState(false); // NEW: Track incorrect attempts

    useEffect(() => {
        if (showModal) {
            start_quiz();
        }
    }, [showModal]);

    const start_quiz = () => {
        setCurrentIndex(0);
        setScore(0);
        setDisabledImages(new Set());
        setFlashEffect('');
        setIncorrectAttempted(false);
        generate_question(0);
    };

    const generate_question = (index) => {
        if (index >= words.length) {
            return;
        }

        const correct = words[index];
        let incorrectChoices = words
            .filter(word => word !== correct)
            .sort(() => 0.5 - Math.random())
            .slice(0, 3);

        const shuffledOptions = [correct, ...incorrectChoices].sort(() => Math.random() - 0.5);

        setCorrectWord(correct);
        setOptions(shuffledOptions);
        setDisabledImages(new Set());
        setFlashEffect('');
        setIncorrectAttempted(false); // Reset for each new word
        play_audio(correct);
    };

    const play_audio = (word) => {
        if (currentAudio) {
            currentAudio.pause();
            setCurrentAudio(null);
        }

        const audio = new Audio(`./audio/dictionary/${word}.mp3`);
        audio.play();
        setCurrentAudio(audio);
    };

    const handle_answer_click = (word) => {
        if (word === correctWord) {
            setFlashEffect(DStyles.correct_flash);
            setTimeout(() => {
                if (currentIndex < words.length - 1) {
                    setCurrentIndex(currentIndex + 1);
                    generate_question(currentIndex + 1);

                    // Only increase score if they got it right on the first try
                    if (!incorrectAttempted) {
                        setScore(score + 1);
                    }
                } else {
                    alert(`Quiz Finished! Final Score: ${score + (incorrectAttempted ? 0 : 1)} / ${words.length}`);
                    setShowModal(false);
                }
            }, 1000);
        } else {
            setFlashEffect(DStyles.incorrect_flash);
            setTimeout(() => {
                setDisabledImages(new Set([...disabledImages, word]));
                setFlashEffect('');
                play_audio(correctWord); // Auto replay audio after incorrect answer
            }, 1000);
            setIncorrectAttempted(true); // Mark that they made a mistake
        }
    };

    return (
        <>
            <button className={DStyles.start_quiz_button} onClick={() => setShowModal(true)}>Start Quiz</button>

            {showModal && (
                <div className={`${DStyles.quiz_modal} ${flashEffect}`}>
                    <div className={DStyles.quiz_content}>
                        <button className={DStyles.close_button} onClick={() => setShowModal(false)}>✖</button>
                        <h2>Vocabulary Quiz</h2>
                        <p>Score: {score} / {words.length}</p>

                        <button className={DStyles.replay_button} onClick={() => play_audio(correctWord)}>
                            🔊 Replay
                        </button>

                        <div className={DStyles.quiz_grid}>
                            {options.map((word, index) => (
                                <div
                                    key={index}
                                    id={word}
                                    className={`${DStyles.quiz_option} ${disabledImages.has(word) ? DStyles.hidden : ''}`}
                                    onClick={() => handle_answer_click(word)}
                                >
                                    <img
                                        src={`./images/dictionary/${word}.jpg`}
                                        alt={word}
                                        className={DStyles.quiz_image}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default VocabularyQuiz;
