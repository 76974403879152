import React, { useState, useEffect } from 'react';
import CEStyles from './styles/CommunityEnglishStyles.module.css';

const Slideshow = ({ slides }) => {
    const [currentIndex, setCurrentIndex] = useState(-1); // Start with no slide displayed
    const [currentAudio, setCurrentAudio] = useState(null);

    const play_audio = () => {
        if (currentAudio) {
            currentAudio.pause();
            setCurrentAudio(null);
        }

        if (currentIndex >= 0) { // Only play audio if a slide is active
            const audio = new Audio(`./audio/lessons/greetings/${slides[currentIndex]}.mp3`);
            audio.play();
            setCurrentAudio(audio);
        }
    };

    const go_to_next_slide = () => {
        if (currentIndex < slides.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };

    const go_to_prev_slide = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
    };

    useEffect(() => {
        if (currentIndex >= 0) {
            play_audio(); // Auto-play audio when slide changes
        }
    }, [currentIndex]);

    return (
        <div className={CEStyles.slideshow_container}>
            {currentIndex === -1 ? ( // Show start container initially
                <div
                    className={CEStyles.start_container}
                    style={{
                        backgroundImage: `url(./images/lessons/greetings/${slides[0]}.jpg)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                >
                    <div className={CEStyles.start_overlay}></div>
                    <button className={CEStyles.start_button} onClick={() => setCurrentIndex(0)}>
                        Start Slideshow
                    </button>
                </div>
            ) : (
                <>
                    <button
                        className={CEStyles.nav_button}
                        onClick={go_to_prev_slide}
                        disabled={currentIndex === 0}
                    >
                        ⬅️
                    </button>

                    <div
                        className={CEStyles.slide}
                        onClick={play_audio} // Clicking the slide replays the audio
                    >
                        <img
                            src={`./images/lessons/greetings/${slides[currentIndex]}.jpg`}
                            alt={slides[currentIndex]}
                            className={CEStyles.slide_image}
                        />
                    </div>

                    <button
                        className={CEStyles.nav_button}
                        onClick={go_to_next_slide}
                        disabled={currentIndex === slides.length - 1}
                    >
                        ➡️
                    </button>
                </>
            )}
        </div>
    );
};

export default Slideshow;
