import React, { useState } from 'react';
import DStyles from './styles/DictionaryStyles.module.css';

const Dictionary = ({words}) => {
    // const [words1, setWords1] = useState([
    //     "cat", "dog", "bird", "fish", "cow", "horse", "pig", "sheep", "chicken", "duck",
    //     "head", "face", "eye", "ear", "nose", "mouth", "hand", "arm", "leg", "foot",
    //     "apple", "banana", "bread", "water", "milk", "rice", "egg", "soup", "meat", "fruit",
    //     "mother", "father", "sister", "brother", "baby", "grandma", "grandpa",
    //     "shirt", "pants", "dress", "shoes", "hat", "coat", "socks", "skirt", "jacket", "tie",
    //     "school", "home", "store", "park", "hospital", "restaurant", "library", "bank", "market", "mall",
    //     "chair", "table", "bed", "sofa", "door", "window", "desk",
    //     "car", "bus", "bike",
    //     "be", "have", "do", "go", "come", "see", "eat", "sleep", "read", "write", "speak", "listen", "run", "walk", "play", "work",
    //     "like", "want", "need", "know",
    //     "good", "bad", "big", "small", "happy", "sad", "hot", "cold", "new", "old", "fast", "slow", "tall", "short", "easy", "difficult",
    //     "in", "on", "at", "by", "with", "from", "to", "for", "about",
    //     "I", "you", "he", "she", "it", "we", "they", "me", "him", "her", "us", "them",
    //     "and", "but", "or", "so", "because", "if", "when",
    //     "a", "an", "the", "this", "that", "these", "those", "some", "any",
    //     "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten"
    // ]);
    //
    // const [words2, setWords2] = useState([
    //     "rabbit", "turtle", "frog", "bee", "ant", "elephant", "lion", "tiger", "bear", "monkey",
    //     "giraffe", "zebra", "kangaroo", "dolphin", "shark", "whale", "crab", "snail", "squirrel",
    //     "shoulder", "knee", "elbow", "finger", "toe", "back", "chest", "stomach",
    //     "carrot", "potato", "tomato", "cucumber", "onion", "garlic", "pepper", "lettuce", "spinach",
    //     "cheese", "pasta", "cake", "cookie", "chocolate", "salad",
    //     "aunt", "uncle", "cousin", "nephew", "niece", "daughter", "son",
    //     "gloves", "scarf", "belt", "sunglasses", "boots", "shorts", "sweater", "underwear",
    //     "office", "church", "post office", "stadium", "cinema", "museum", "zoo", "beach",
    //     "lamp", "cabinet", "shelf", "rug", "mirror", "dresser", "stool", "bookshelf",
    //     "truck", "van", "train", "airplane", "boat", "taxi", "scooter",
    //     "red", "blue", "green", "yellow", "orange", "purple", "black", "white", "brown",
    //     "sun", "rain", "snow", "wind", "cloud",
    //     "book", "pencil", "pen", "paper", "eraser", "backpack",
    //     "learn", "buy", "sell", "cook", "drive", "fly", "think", "feel", "say", "call",
    //     "help", "open", "close", "stop", "start", "begin", "finish", "build", "create",
    //     "live", "love", "watch", "sit", "stand", "smile", "cry", "try", "use", "wait",
    //     "give", "take", "bring", "look",
    //     "beautiful", "ugly", "funny", "serious", "loud", "quiet", "rich", "poor",
    //     "clean", "dirty", "strong", "weak", "light", "dark", "soft", "hard",
    //     "full", "empty", "bright", "dull", "expensive", "cheap",
    //     "very", "really", "always", "never", "often", "sometimes", "now", "then",
    //     "here", "there", "quickly", "slowly", "under", "over", "between", "among",
    //     "through", "during",
    //     "my", "your", "his", "her", "our", "their", "mine", "yours", "myself",
    //     "although", "since", "while", "however",
    //     "each", "every", "many", "few",
    //     "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen", "twenty"
    // ]);
    //
    // const [words3, setWords3] = useState([
    //     "deer", "wolf", "fox", "owl", "eagle", "penguin", "leopard", "camel", "panda", "koala",
    //     "hair", "chin", "cheek", "lips", "tooth", "tongue",
    //     "grape", "strawberry", "lemon", "lime", "watermelon", "pineapple", "mango", "peach", "plum", "cherry",
    //     "husband", "wife", "parent", "child", "relative",
    //     "blouse", "suit", "vest", "cardigan", "pajamas", "sneakers",
    //     "airport", "station", "bakery", "pharmacy", "cafe", "hotel",
    //     "armchair", "couch", "wardrobe", "drawer",
    //     "motorcycle", "subway", "tram", "ferry",
    //     "pink", "gray", "violet", "turquoise",
    //     "storm", "fog", "thunder",
    //     "notebook", "marker", "crayon",
    //     "teacher", "doctor", "nurse", "engineer", "lawyer", "farmer", "chef", "waiter", "firefighter", "scientist",
    //     "mountain", "river", "lake", "forest", "desert", "valley",
    //     "medicine", "clinic", "symptom", "diagnosis", "treatment",
    //     "freedom", "responsibility", "opportunity", "challenge", "success", "failure", "experience", "knowledge",
    //     "computer", "internet", "smartphone", "email", "website", "software", "hardware", "keyboard", "printer", "camera",
    //     "consider", "explain", "describe", "improve", "develop", "manage", "prepare", "suggest", "decide", "discuss",
    //     "plan", "recommend", "imagine", "believe", "include", "involve", "avoid", "reduce", "increase", "provide",
    //     "examine", "explore", "discover", "maintain", "organize", "support", "investigate", "solve", "achieve", "handle",
    //     "evaluate", "measure", "express", "communicate", "contribute",
    //     "interesting", "boring", "complex", "simple", "modern", "traditional", "efficient", "effective", "valuable",
    //     "important", "necessary", "popular", "common", "unique", "reliable", "creative", "flexible", "curious",
    //     "practical", "delicious", "advanced", "challenging", "enthusiastic", "exceptional", "motivated", "optimistic",
    //     "pessimistic", "remarkable", "significant", "vibrant", "versatile", "dynamic", "ambitious",
    //     "frequently", "occasionally", "gradually", "suddenly", "easily", "simply", "seriously", "clearly", "ultimately",
    //     "finally", "before", "after", "around", "beside", "against",
    //     "everyone", "someone", "anybody", "nobody",
    //     "though", "unless", "whereas",
    //     "another", "other",
    //     "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety", "hundred"
    // ]);
    const [currentAudio, setCurrentAudio] = useState(null);

    const play_audio = (word) => {
        if (currentAudio) {
            currentAudio.pause();
            setCurrentAudio(null);
        }

        const audio = new Audio(`./audio/dictionary/${word}.mp3`);
        audio.play();
        setCurrentAudio(audio);
    };

    return (
        <div className={DStyles.dictionary_container}>
            {words.map((word, index) => (
                <div
                    key={index}
                    className={DStyles.word_card}
                    onClick={() => play_audio(word)} // Clicking anywhere in the card plays audio
                >
                    <div className={DStyles.word_text}>{word}</div>
                    <img src={`./images/dictionary/${word}.jpg`} alt={word} className={DStyles.word_image} />
                    <button
                        onClick={(e) => { e.stopPropagation(); play_audio(word); }}
                        className={DStyles.play_button}
                    >
                        ▶️ Play
                    </button>
                </div>
            ))}
        </div>
    );
};

export default Dictionary;
