import React, {useEffect, useState, useRef} from "react";
import GenStyles from "./styles/GenStyles.module.css";
import {talk} from "./ALI_Ajax.js";
import RMStyles from "./styles/ResultsManagerStyles.module.css";
import {useUser} from "./UserContext";
import LoginModule from "./login_module";
import RegisterExamineeForm from "./examinee_registration_form";
import EditExamineeForm from "./examinee_edit_form";



const ResultsManager = (props) => {
    const [prompt, setPrompt] = useState ('');
    const [pin, setPin] = useState('');
    const chatInputRef = useRef(null);
    const [filename, setFilename] = useState('');
    const [audioTag, setAudioTag] = useState(<></>);
    const [selectedRange, setSelectedRange] = useState(null);
    const [searchActive, setSearchActive] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [examinees, setExaminees] = useState([]);
    const {userId, finishedLoggingIn, is_user_in_role} = useUser();
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [editingUser, setEditingUser] = useState(false);


    //USE EFFECTS
    useEffect(() => {
        if(userId && examinees.length === 0) {
            get_examinees();
        }
    }, [userId]);

    //AJAX FUNCTIONS
    const get_examinees = () => {
        talk("ali_api/ALIExaminee_Manager_Handler.php",
            "GET_ALL_EXAMINEES",
            null,
            get_examinees_handler);
    }
    const get_examinees_handler = (operation, sent_data, return_data) => {
        if(return_data['status'] === 'error') {
            console.log("ERROR: " + return_data['message']);
        } else {
            setExaminees(return_data['message']);
        }
    }
    const save_edit_examinee = (updated_info) => {
        console.log(updated_info);
    }

    //OTHER FUNCTIONS
    const handle_range_button_click = (range) => {
        setShowRegisterForm(false);
        if(range === selectedRange) {
            setSelectedRange(null);
        } else {
            setSelectedRange(range);
        }
        setSearchActive(false); // Deactivate search mode when a range is selected
        setSearchQuery(""); // Clear any existing search query
    };
    const handle_search_toggle = () => {
        setShowRegisterForm(false);
        setSearchActive(!searchActive);
        setSelectedRange(null); // Deactivate range mode when search is active
        if (!searchActive) setSearchQuery(""); // Clear search query when activating search mode
    };
    // Function to filter users based on selected range
    const get_filtered_users = () => {
        if (searchActive && searchQuery) {
            // Filter by search query
            return examinees.filter(
                (user) =>
                    user.firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    user.lastname.toLowerCase().includes(searchQuery.toLowerCase())
            );
        } else if (selectedRange) {
            // Filter by range
            const rangeMap = {
                "A-C": /^[A-C]/i,
                "D-G": /^[D-G]/i,
                "H-L": /^[H-L]/i,
                "M-Q": /^[M-Q]/i,
                "R-T": /^[R-T]/i,
                "U-Z": /^[U-Z]/i,
            };

            const regex = rangeMap[selectedRange];
            return examinees.filter((user) => regex.test(user.lastname));
        }
        // If no filter is active, return all users
        return examinees;
    };
    const handle_plus_button_click = () => {
        setSelectedRange(null);
        setSearchActive(false);
        setShowRegisterForm(!showRegisterForm);
    };
    const select_user = (user) => {
        setSelectedUser(user);
        setEditingUser(true);
        setSearchQuery(null);
        setSearchActive(null);
        setSelectedRange(null);
    }
    const cancel_editing_examinee = () => {
        setEditingUser(false);
    }
    //RENDERING
    return (
        <>
        {
            finishedLoggingIn  && is_user_in_role(['ADMIN', 'EVALUATOR', 'MANAGER']) ?
                <>
                    <div className={GenStyles.container} style={{marginTop: "80px"}} id="showcase">
                        <h1 className={GenStyles.jumbo}><b>ALI</b></h1>
                        <h1 className={`${GenStyles.xxxlarge} ${GenStyles.text_umkc_blue}`}><b>Results</b></h1>
                        <hr style={{width: "50px", border: "5px solid #ffd52f"}} className={GenStyles.round}/>
                    </div>
                    {userId ?
                        <div className={RMStyles.container}>
                            <h1 className={RMStyles.title}>Completed Tests</h1>

                            <table className={showRegisterForm ? GenStyles.hidden : RMStyles.table}>
                                <thead>
                                <tr>
                                    <th className={RMStyles.th}>First Name</th>
                                    <th className={RMStyles.th}>Last Name</th>
                                    <th className={RMStyles.th}>User ID</th>
                                </tr>
                                </thead>
                                <tbody>
                                {get_filtered_users() && get_filtered_users().map((user) => (
                                    <tr
                                        key={user.user_id}
                                        className={RMStyles.tr}
                                        onClick={() => select_user(user)}
                                    >
                                        <td className={RMStyles.td}>{user.firstname}</td>
                                        <td className={RMStyles.td}>{user.lastname}</td>
                                        <td className={RMStyles.td}>{user.user_id}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        : <LoginModule/>}
                </> :
                <div>loading</div>
            }
        </>
    )
}

export default ResultsManager;
